import { SecondaryButton, Tooltip } from '@shared/components'
import {
  Appointment,
  AppointmentModel,
  Form,
  InductionNoteContent,
  Patient,
  VisitNoteContent,
  VisitNoteType,
  hasAppointmentType,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { visitNotesApi } from '../../../api'
import ANoteLoading from '../../../components/atoms/ANoteLoading'
import IconCopy from '../../../components/icons/IconCopy'
import { InductionVisitForm } from './InductionVisitForm'
import { InitialWellnessVisitForm } from './InitialWellnessVisitForm'
import OCheckInCallVisitForm from './OCheckInCallVisitForm'
import OIntakeFormSidebar from './OIntakeFormSidebar'
import OLegacyVisitForm from './OLegacyVisitForm'
import OMaintenanceVisitForm from './OMaintenanceVisitForm'
import OUdsVisitForm from './OUdsVisitForm'
import PeerCheckinForm from './PeerCheckinForm'
import { WellnessVisitForm } from './WellnessVisitForm'
import { PatientVisitNoteProvider } from './updatedVisitNotes/PatientVisitNoteContext'
import { ReenrollmentCallVisitNote } from './updatedVisitNotes/ReenrollmentCallVisitNote'
import { WelcomeCallVisitNote } from './updatedVisitNotes/WelcomeCallVisitNote'

type OVisitNotesSidebarProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  copyNote: (notes: Record<string, any> | null) => void
  patientID: string
  pastAppointments: AppointmentModel[]
  editMode: boolean
  intakeForm: Form | null
  isLoading: boolean
  priorIntake?: Appointment | undefined
  visit?: Appointment | undefined
}

const OVisitNotesSidebar = ({
  copyNote,
  patientID,
  pastAppointments,
  editMode,
  intakeForm,
  isLoading,
  priorIntake,
  visit,
}: OVisitNotesSidebarProps) => {
  const { register, watch, setValue } = useForm()
  const [lastVisitNotes, setLastVisitNotes] = useState<VisitNoteContent | null>(null)
  const [lastVisitType, setLastVisitType] = useState<VisitNoteType>()
  const visitID = watch('visitID')

  // Initialize visitID to the first past appointment
  useEffect(() => {
    if (!pastAppointments) {
      return
    }
    if (pastAppointments.length === 0) {
      return
    }
    // If visitID is already set, don't change it
    if (visitID) {
      return
    }

    if (priorIntake) {
      setValue('visitID', priorIntake.id)
      return
    }

    if (hasAppointmentType(visit, 'Initial Visit') && intakeForm) {
      setValue('visitID', 'medical-intake-form')
      return
    }

    setValue('visitID', pastAppointments[0]?.id)
  }, [pastAppointments, setValue, priorIntake, intakeForm, visit])

  const visitNoteQuery = useQuery(
    ['visitNotesApi.getVisitNote', patientID, visitID],
    () => visitNotesApi.getVisitNote({ patientID, visitID }),
    {
      enabled: Boolean(visitID) && visitID !== 'medical-intake-form',
      onSuccess: note => {
        if (!note) {
          return
        }
        if (note.induction) {
          setLastVisitNotes(note.induction)
          setLastVisitType('induction')
        } else if (note.maintenance) {
          setLastVisitNotes(note.maintenance)
          setLastVisitType('maintenance')
        } else if (note.uds) {
          setLastVisitNotes(note.uds)
          setLastVisitType('uds')
        } else if (note.peercheckin) {
          setLastVisitNotes(note.peercheckin)
          setLastVisitType('peercheckin')
        } else if (note.blank) {
          setLastVisitNotes(note.blank)
          setLastVisitType('blank')
        } else if (note.bhcm) {
          setLastVisitNotes(note.bhcm)
          setLastVisitType('bhcm')
        } else if (note.initialBhcm) {
          setLastVisitNotes(note.initialBhcm)
          setLastVisitType('initialBhcm')
        } else if (note.consultation) {
          setLastVisitNotes(note.consultation)
          setLastVisitType('consultation')
        } else if (note.reenrollment) {
          setLastVisitNotes(note.reenrollment)
          setLastVisitType('reenrollment')
        } else {
          setLastVisitNotes(note.legacy)
          setLastVisitType('legacy')
        }
      },
    },
  )

  return (
    <div className='border-separator-light border-l hidden lg:flex xl:flex flex-shrink-0 z-10'>
      <div className='flex flex-col w-full mr-4'>
        <div className='sticky top-0 h-16 w-full bg-near-white z-25'>
          <div className='flex flex-row h-full'>
            <div className='border-b border-separator-light text-readable-small-gray w-full flex justify-end items-center py-4'>
              <div className='flex flex-row justify-end mr-4 w-full'>
                {pastAppointments && !isLoading && (
                  <form>
                    <div className='relative py-2 pl-2'>
                      <select
                        id='visitID'
                        name='visitID'
                        ref={register()}
                        className='block w-full pl-3 pr-10 py-2 text-base border-gray-300 z-10 focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm rounded-md'
                      >
                        {intakeForm && (
                          <option value='medical-intake-form' key='medical-intake-form'>
                            Medical Intake Form
                          </option>
                        )}
                        {pastAppointments.map(appointment => (
                          <option value={appointment.id} key={appointment.id}>
                            {appointment.type} on{' '}
                            {dayjs(appointment.datetime).format('MMMM Do, YYYY')}
                          </option>
                        ))}
                      </select>
                    </div>
                  </form>
                )}
              </div>
              <div className='mr-4'>
                {visitID !== 'medical-intake-form' && !isLoading && (
                  <Tooltip
                    label='Copy visit notes into current note'
                    style={{ zIndex: 100 }}
                    position='bottom'
                    offset={5}
                  >
                    <SecondaryButton
                      leftIcon={<IconCopy />}
                      disabled={!editMode}
                      onClick={() => copyNote(lastVisitNotes)}
                      style={{ cursor: 'pointer' }}
                    ></SecondaryButton>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>

        {visitID === 'medical-intake-form' && intakeForm ? (
          <OIntakeFormSidebar intakeForm={intakeForm} />
        ) : (
          <div className='flex-grow-1 flex-shrink-0 w-full h-full px-2'>
            {!visitNoteQuery.isLoading && !isLoading && (
              <div className='px-2 flex-grow w-full flex flex-col items-center'>
                {lastVisitType === 'maintenance' && (
                  <OMaintenanceVisitForm
                    setShowCaseReviewNote={null}
                    patientID={patientID}
                    notes={lastVisitNotes}
                    editMode={false}
                    sidebar
                    closedNoteDetails={null}
                    visit={null}
                    setCloseNoteModal={null}
                    setShowAttestationModal={null}
                    patient={null}
                    meetingDetails={null}
                    setUnlockNoteModal={null}
                    setMedicationPrescribed={null}
                    handleTobaccoUseDiagnosis={() => null}
                    openDischargeNotesDrawer={null}
                  />
                )}
                {lastVisitType === 'peercheckin' && (
                  <PeerCheckinForm
                    patientID={patientID}
                    notes={lastVisitNotes}
                    editMode={false}
                    sidebar
                    visitID=''
                  />
                )}
                {lastVisitType === 'uds' && (
                  <OUdsVisitForm
                    patientID={patientID}
                    notes={lastVisitNotes}
                    editMode={false}
                    sidebar
                    visitID=''
                    closedNoteDetails={undefined}
                    setCloseNoteModal={() => null}
                    visit={{} as Appointment}
                    patient={{} as Patient}
                    meetingDetails={null}
                  />
                )}
                {lastVisitType === 'induction' && (
                  <InductionVisitForm
                    patientID={patientID}
                    notes={lastVisitNotes as InductionNoteContent}
                    sidebar
                    handleTobaccoUseDiagnosis={() => null}
                  />
                )}
                {lastVisitType === 'blank' && (
                  <OCheckInCallVisitForm
                    patientID={patientID}
                    notes={lastVisitNotes}
                    editMode={false}
                    sidebar
                    visitID=''
                    closedNoteDetails={null}
                    meetingDetails={null}
                    visit={null}
                    setCloseNoteModal={() => null}
                  />
                )}
                {lastVisitType === 'bhcm' && (
                  <WellnessVisitForm
                    patientID={patientID}
                    notes={lastVisitNotes}
                    editMode={false}
                    sidebar
                    setCloseNoteModal={() => {
                      // do nothing since this is sidebar view
                    }}
                    setUnlockNoteModal={() => {
                      // do nothing since this is sidebar view
                    }}
                  />
                )}
                {lastVisitType === 'initialBhcm' && (
                  <InitialWellnessVisitForm
                    patientID={patientID}
                    notes={lastVisitNotes}
                    editMode={false}
                    sidebar
                    setCloseNoteModal={() => {
                      // do nothing since this is sidebar view
                    }}
                    setUnlockNoteModal={() => {
                      // do nothing since this is sidebar view
                    }}
                  />
                )}
                {lastVisitType === 'consultation' && (
                  <PatientVisitNoteProvider visitNoteId={visitID} isSidebar>
                    <WelcomeCallVisitNote key={visitID} />
                  </PatientVisitNoteProvider>
                )}
                {lastVisitType === 'reenrollment' && (
                  <PatientVisitNoteProvider visitNoteId={visitID} isSidebar>
                    <ReenrollmentCallVisitNote key={visitID} />
                  </PatientVisitNoteProvider>
                )}
                {lastVisitType === 'legacy' && (
                  <OLegacyVisitForm
                    patientID={patientID}
                    notes={lastVisitNotes}
                    editMode={false}
                    sidebar
                    visitID=''
                    closedNoteDetails={null}
                    visit={null}
                    setCloseNoteModal={() => null}
                  />
                )}
              </div>
            )}
            {(visitNoteQuery.isLoading || isLoading) && <ANoteLoading />}
          </div>
        )}
      </div>
    </div>
  )
}

export default OVisitNotesSidebar
