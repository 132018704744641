import {
  AlertIcon,
  Center,
  EditIcon,
  EM_DASH,
  Group,
  Menu,
  MoreVerticalIcon,
  ScrollAreaAutosize,
  SecondaryButton,
  Stack,
  Sx,
  Table,
  Td,
  TertiaryButton,
  Text,
  Th,
  Tooltip,
  useMantineTheme,
} from '@shared/components'
import { Day, Employee, HourRange, isClinician, isConsultationCallQueue } from '@shared/types'
import { dayjs } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import range from 'lodash/range'
import { Dispatch, SetStateAction, useState } from 'react'
import LoadingRow from '../../../components/atoms/LoadingRow'
import NoDataRow from '../../../components/atoms/NoDataRow'
import { useEmployees } from '../../../utils/hooks'
import { useAccess } from '../../../utils/hooks/use-access'
import { EditWorkingHoursDrawer } from './EditWorkingHoursDrawer'

const NUMBER_OF_LOADING_ROWS = 7
const DAYS_OF_THE_WEEK: Day[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]
const COLUMNS = [
  'Employee',
  'Role',
  'States',
  ...DAYS_OF_THE_WEEK.map(d => capitalize(d)),
  'Action',
]

const renderRole = (role: Employee['role']) => {
  if (role === 'ncm' || role === 'sncm') {
    return 'CCM'
  }

  if (role === 'ncm_tn') {
    return 'CCM/TN'
  }

  return role.toUpperCase()
}

const HourRangesTd = ({
  hourRanges,
  showAlert = false,
}: {
  hourRanges: HourRange[] | undefined
  showAlert?: boolean
}) => {
  if (!hourRanges?.length) {
    return (
      <Td>
        {showAlert ? (
          <Tooltip
            openDelay={1200}
            label={<Text color={colors => colors.text[3]}>No working hours this day</Text>}
          >
            <AlertIcon color={colors => colors.warning[0]} />
          </Tooltip>
        ) : (
          <Text color={colors => colors.text[1]}>{EM_DASH}</Text>
        )}
      </Td>
    )
  }
  return (
    <Td>
      <Stack spacing='sm'>
        {hourRanges.map((range, i) => {
          const [startHour, startMinute] = range.start.split(':').map(Number) as [number, number]
          const [endHour, endMinute] = range.end.split(':').map(Number) as [number, number]

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Text key={i}>
              {dayjs().tz(dayjs.tz.guess()).hour(startHour).minute(startMinute).format('h:mma z')}
              {EM_DASH}
              {dayjs().tz(dayjs.tz.guess()).hour(endHour).minute(endMinute).format('h:mma z')}
            </Text>
          )
        })}
      </Stack>
    </Td>
  )
}

export const EmployeeTables = () => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  const hasAccess = useAccess()

  const tableStyling: Sx = {
    flex: 1,
    tableLayout: 'auto',
    backgroundColor: colors.background[0],
    borderWidth: sizes.border.md,
    borderStyle: 'solid',
    borderColor: colors.background[3],
    '& td': { verticalAlign: 'top' },
  }

  const [editingEmployee, setEditingEmployee] = useState<Employee['oid']>()

  const employeesQuery = useEmployees({ status: 'currentEmployee' })
  const employees = employeesQuery.data || []

  const { isLoading } = employeesQuery

  return (
    <Stack p='lg'>
      <EditWorkingHoursDrawer
        employeeId={editingEmployee}
        onClose={() => setEditingEmployee(undefined)}
      />
      {hasAccess.clinicianWorkingHours && (
        <CliniciansTable
          employees={employees}
          isLoading={isLoading}
          tableStyling={tableStyling}
          setEditingEmployee={setEditingEmployee}
          fullHeight={!hasAccess.consultationCallQueueWorkingHours}
        />
      )}
      {hasAccess.consultationCallQueueWorkingHours && (
        <ConsultationCallQueueTable
          employees={employees}
          isLoading={isLoading}
          tableStyling={tableStyling}
          setEditingEmployee={setEditingEmployee}
        />
      )}
    </Stack>
  )
}

type EmployeeTableChildrenProps = {
  employees: Employee[]
  isLoading: boolean
  setEditingEmployee: Dispatch<SetStateAction<string | undefined>>
  tableStyling: Sx
  fullHeight?: boolean
}

export const CliniciansTable = ({
  employees,
  isLoading,
  setEditingEmployee,
  tableStyling,
  fullHeight,
}: EmployeeTableChildrenProps) => {
  const clinicians: Employee<'clinician'>[] = employees.filter(isClinician)

  return (
    <ScrollAreaAutosize constrict={false} maxHeight={fullHeight ? '85vh' : '70vh'} type='hover'>
      <Table striped withBorder verticalSpacing='sm' sx={tableStyling}>
        <thead className='mantine'>
          <tr className='mantine'>
            {COLUMNS.map(header => (
              <Th key={header} sortable={false}>
                {header}
              </Th>
            ))}
          </tr>
        </thead>
        <tbody className='mantine'>
          {isLoading &&
            range(0, NUMBER_OF_LOADING_ROWS + 1).map(i => (
              <LoadingRow key={i} headersLength={COLUMNS.length} />
            ))}
          {!isLoading && clinicians.length === 0 && (
            <NoDataRow message='No clinicians found' headersLength={COLUMNS.length} />
          )}
          {!isLoading &&
            clinicians.length > 0 &&
            clinicians.map(clinician => {
              return (
                <tr key={clinician.oid}>
                  <Td>
                    <TertiaryButton
                      component='a'
                      target='_blank'
                      href={`/employees/${clinician.oid}`}
                    >
                      {clinician.name}
                    </TertiaryButton>
                  </Td>
                  <Td>
                    <Text>{renderRole(clinician.role)}</Text>
                  </Td>
                  <Td>
                    <Text>
                      {clinician.providerInfo?.licenses
                        // Ensure it's active
                        ?.filter(license => license.isCurrentlyPracticing)
                        // We only need the state
                        ?.map(license => license.state)
                        // Filter out duplicates
                        ?.filter((item, i, arr) => arr.indexOf(item) === i)
                        // Join for rendering
                        ?.join(', ') || (
                        <Group spacing='sm'>
                          <Tooltip
                            openDelay={1200}
                            label={<Text color={colors => colors.text[3]}>No active states</Text>}
                          >
                            <AlertIcon color={colors => colors.error[0]} />
                          </Tooltip>
                        </Group>
                      )}
                    </Text>
                  </Td>
                  {DAYS_OF_THE_WEEK.map(dayOfTheWeek => (
                    <HourRangesTd
                      key={dayOfTheWeek}
                      hourRanges={clinician.workingHours?.settings[dayOfTheWeek]}
                    />
                  ))}
                  <Td>
                    <Group position='left'>
                      <Menu position='bottom-end'>
                        <Center>
                          <Menu.Target>
                            <SecondaryButton size='xs' leftIcon={<MoreVerticalIcon />} />
                          </Menu.Target>
                        </Center>
                        <Menu.Dropdown>
                          <Menu.Item
                            onClick={() => setEditingEmployee(clinician.oid)}
                            icon={<EditIcon color={colors => colors.actions[0]} />}
                          >
                            Edit
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>
                    </Group>
                  </Td>
                </tr>
              )
            })}
        </tbody>
      </Table>
    </ScrollAreaAutosize>
  )
}

export const ConsultationCallQueueTable = ({
  employees,
  isLoading,
  setEditingEmployee,
  tableStyling,
}: EmployeeTableChildrenProps) => {
  const consultationCallQueueEmployee: Employee<'consultation-call-queue'>[] =
    employees.filter(isConsultationCallQueue)

  return (
    <ScrollAreaAutosize constrict={false} maxHeight='20vh' type='hover'>
      <Table striped withBorder verticalSpacing='sm' sx={tableStyling}>
        <thead className='mantine'>
          <tr className='mantine'>
            {COLUMNS.filter(c => c !== 'States' && c !== 'Role').map(header => (
              <Th key={header} sortable={false}>
                {header}
              </Th>
            ))}
          </tr>
        </thead>

        <tbody className='mantine'>
          {isLoading && range(0, 1).map(i => <LoadingRow key={i} headersLength={COLUMNS.length} />)}
          {!isLoading && consultationCallQueueEmployee.length === 0 && (
            <NoDataRow message='No consultation call queue found' headersLength={COLUMNS.length} />
          )}
          {!isLoading &&
            consultationCallQueueEmployee.length > 0 &&
            consultationCallQueueEmployee.map(callQueueEmployee => {
              return (
                <tr key={callQueueEmployee.oid}>
                  <Td>
                    <TertiaryButton
                      component='a'
                      target='_blank'
                      href={`/employees/${callQueueEmployee.oid}`}
                    >
                      {callQueueEmployee.name}
                    </TertiaryButton>
                  </Td>
                  {DAYS_OF_THE_WEEK.map(dayOfTheWeek => (
                    <HourRangesTd
                      key={dayOfTheWeek}
                      showAlert
                      hourRanges={callQueueEmployee.workingHours?.settings[dayOfTheWeek]}
                    />
                  ))}
                  <Td>
                    <Group position='left'>
                      <Menu position='bottom-end'>
                        <Center>
                          <Menu.Target>
                            <SecondaryButton size='xs' leftIcon={<MoreVerticalIcon />} />
                          </Menu.Target>
                        </Center>
                        <Menu.Dropdown>
                          <Menu.Item
                            onClick={() => setEditingEmployee(callQueueEmployee.oid)}
                            icon={<EditIcon color={colors => colors.actions[0]} />}
                          >
                            Edit
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>
                    </Group>
                  </Td>
                </tr>
              )
            })}
        </tbody>
      </Table>
    </ScrollAreaAutosize>
  )
}
