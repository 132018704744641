import { Appointment, Employee, getVisitTypeName, isClinician } from '@shared/types'
import { template, zoom } from '@shared/utils'

export const getVisitTypeLabel = (appointment: Appointment) => {
  return template(['{visitType}', '({duration} min)'], {
    visitType: getVisitTypeName(appointment.type),
    duration: appointment.type === 'Follow-Up Visit' ? appointment?.duration : undefined,
  })
}

export const getZoomUrl = (currentUser: Employee, appointment: Appointment | null) => {
  const meetingCanceled = appointment?.canceled

  if (isClinician(currentUser)) {
    if (currentUser.oid !== appointment?.employeeId) {
      // This is not the same employee ID as the appointment's clinician ID, so use the general join url
      return {
        meetingCanceled,
        zoomUrl: zoom(appointment?.metadata?.virtualAppointmentDetails?.generalJoinUrl).zoomUrl,
        patientJoinUrl: zoom(
          appointment?.metadata?.virtualAppointmentDetails?.patientJoinUrl ?? appointment?.location,
        ).zoomUrl,
        urlToSendToPatient: zoom(
          appointment?.metadata?.virtualAppointmentDetails?.patientRedirectUrl,
        ).zoomUrl,
        meetingId: appointment?.metadata?.virtualAppointmentDetails?.id,
        passcode: appointment?.metadata?.virtualAppointmentDetails?.password,
      }
    }

    return (
      // Fall back to appointment?.location in the case that this is a very, very old scheduled appointment
      {
        meetingCanceled,
        zoomUrl: zoom(
          appointment?.metadata?.virtualAppointmentDetails?.clinicianJoinUrl ??
            appointment?.location,
        ).zoomUrl,
        patientJoinUrl: zoom(
          appointment?.metadata?.virtualAppointmentDetails?.patientJoinUrl ?? appointment?.location,
        ).zoomUrl,
        urlToSendToPatient: zoom(
          appointment?.metadata?.virtualAppointmentDetails?.patientRedirectUrl,
        ).zoomUrl,
        meetingId: appointment?.metadata?.virtualAppointmentDetails?.id,
        passcode: appointment?.metadata?.virtualAppointmentDetails?.password,
      }
    )
  }

  return {
    meetingCanceled,
    zoomUrl: zoom(appointment?.metadata?.virtualAppointmentDetails?.generalJoinUrl).zoomUrl,
    patientJoinUrl: zoom(
      appointment?.metadata?.virtualAppointmentDetails?.patientJoinUrl ?? appointment?.location,
    ).zoomUrl,
    urlToSendToPatient: zoom(appointment?.metadata?.virtualAppointmentDetails?.patientRedirectUrl)
      .zoomUrl,
    meetingId: appointment?.metadata?.virtualAppointmentDetails?.id,
    passcode: appointment?.metadata?.virtualAppointmentDetails?.password,
  }
}
