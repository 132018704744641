import { Alert, FlagIcon, List, ListItem } from '@shared/components'
import { Patient } from '@shared/types'
import { isScheduledForDischarge } from '../../pages/patient/visits/DischargeVisitDrawer'

export const DischargeAlert = ({ patient }: { patient: Patient | undefined }) => {
  if (isScheduledForDischarge({ patient })) {
    const discharge = patient?.discharge

    return (
      <Alert
        variant='primary'
        title='Set for discharge; patient can only be scheduled up to 2 days prior to discharge date.'
        icon={<FlagIcon />}
      >
        <List
          sx={({ other: { colors }, fontSizes }) => ({
            color: colors.text[3],
            fontSize: fontSizes.xs,
          })}
        >
          <ListItem>
            Projected discharge on {discharge?.date} due to {discharge?.reason}
          </ListItem>
          {discharge?.note ? <ListItem>Discharge notes: {discharge.note}</ListItem> : null}
        </List>
      </Alert>
    )
  }

  return null
}
