import { createRoot } from 'react-dom/client'
import { Root } from './Root'
import './index.css'
import * as FullStory from './utils/fullstory'

void (() => {
  FullStory.init()

  const container = document.getElementById('root')
  const root = createRoot(container as HTMLElement)

  root.render(<Root />)
})()
