import { useClipboard, useDisclosure } from '@mantine/hooks'
import {
  CalendarIcon,
  CheckIcon,
  ClipboardIcon,
  ClockIcon,
  Divider,
  Flex,
  Group,
  LinkIcon,
  MapIcon,
  PlusIcon,
  SecondaryButton,
  Skeleton,
  Stack,
  Switch,
  Text,
  TitleTwo,
  Tooltip,
  UserCheckIcon,
  UserIcon,
  VideoIcon,
  VideoOffIcon,
  XIcon,
  showNotification,
  useBanner,
} from '@shared/components'
import {
  Appointment,
  Employee,
  getVisitTypeName,
  hasAppointmentType,
  hasGroupRole,
  isAdmin,
  isCoordinator,
  isEngineer,
  isLeadCoordinator,
  isPatientOnboardingOffline,
} from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { useMutation } from 'react-query'
import { analytics } from '../../../../analytics'
import { appointmentsApi } from '../../../../api'
import { useAuth } from '../../../../context/auth'
import { useEmrQuery, useInvalidateQuery } from '../../../../utils/hooks'
import { useAccess } from '../../../../utils/hooks/use-access'
import { usePatientWorkflowSession } from '../../../../utils/hooks/use-patient-workflow-session'
import { getZoomUrl } from '../../../../utils/visits'
import CancelVisitModal from '../../../calendar/CancelVisitModal'
import { usePatient } from '../../PPatientContext'
import RestoreVisitModal from '../RestoreVisitModal'
import { useCarePathway } from '../carePathways'
import { CreateAddendumModal } from './CreateAddendumModal'
import { usePatientVisitNote } from './PatientVisitNoteContext'
import { VersionHistoryModal } from './VersionHistoryModal'
import { PatientVisitNoteLoader } from './visitNoteForm/PatientVisitNoteLoader'

const getScheduledByLabel = ({
  visit,
  employee,
}: {
  visit: Appointment
  employee: Employee | undefined
}) => {
  if (visit.createdBy === 'system') {
    return `Scheduled automatically`
  }

  if (visit.createdBy === 'ophelia_employee') {
    return `Scheduled by ${employee?.name ?? 'employee'}`
  }

  if (visit.createdBy === 'patient') {
    return `Scheduled by patient`
  }
}

export type VisitNoteHeaderProps = {}

export const PatientVisitNoteHeader = () => {
  const { currentUser } = useAuth()
  const hasAccess = useAccess()
  const { patientId, patientQuery, setModal } = usePatient()
  const [isAddendumModalOpen, updateAddendumModal] = useDisclosure()
  const [isVersionHistoryModalOpen, updateVersionHistoryModal] = useDisclosure()
  const [isCancelVisitModalOpen, updateCancelVisitModal] = useDisclosure()
  const [isRestoreVisitModalOpen, updateRestoreVisitModal] = useDisclosure()
  const { copy, copied } = useClipboard({ timeout: toTime('3 sec').ms() })

  const modals = {
    addendum: {
      isModalOpen: isAddendumModalOpen,
      modal: updateAddendumModal,
    },
    versionHistory: {
      isModalOpen: isVersionHistoryModalOpen,
      modal: updateVersionHistoryModal,
    },
    cancelVisit: {
      isModalOpen: isCancelVisitModalOpen,
      modal: updateCancelVisitModal,
    },
    restoreVisit: {
      isModalOpen: isRestoreVisitModalOpen,
      modal: updateRestoreVisitModal,
    },
  }
  const invalidate = useInvalidateQuery()

  const { drawer: onboardingWorkflowDrawer, openWorkflow: openOnboardingWorkflow } =
    usePatientWorkflowSession({
      patientId,
      workflowType: 'onboarding',
    })

  const { drawer: medicalIntakeFormDrawer, openWorkflow: openMedicalIntakeForm } =
    usePatientWorkflowSession({
      patientId,
      workflowType: 'medical_intake_form',
    })

  const { appointmentQuery, canEdit } = usePatientVisitNote()
  const visit = appointmentQuery?.data

  const carePathway = useCarePathway()

  const confirmAppointment = useMutation(appointmentsApi.confirm, {
    onSuccess: () => {
      void invalidate('GET /patient/:patientId/appointments/:appointmentId', {
        params: {
          patientId,
          appointmentId: String(visit?.id),
        },
      })
    },
  })

  const { data: patient } = useEmrQuery('GET /patient/:patientId', { params: { patientId } })
  const isOnboardingOffline = isPatientOnboardingOffline(patient)

  const { zoomUrl, urlToSendToPatient, meetingId, passcode } = getZoomUrl(
    currentUser,
    visit || null,
  )

  const handleCopyZoomLink = () => {
    if (isOnboardingOffline && meetingId && passcode) {
      copy(`Meeting Id: ${meetingId}, Passcode: ${passcode}`)
      showNotification({
        message: <Text bold>Copied Zoom meeting details</Text>,
        variant: 'success',
        autoClose: true,
      })
    } else if (urlToSendToPatient) {
      copy(urlToSendToPatient)
      showNotification({
        message: <Text bold>Copied Zoom link</Text>,
        variant: 'success',
        autoClose: true,
      })
    } else {
      showNotification({
        message: <Text bold>Zoom details not available</Text>,
        variant: 'error',
        autoClose: true,
      })
    }
  }

  const openZoomRoom = () => {
    if (!zoomUrl) {
      // TODO: generate a Zoom url if one doesn't exist?
      return
    }

    const APPOINTMENT_CUTOFF_MINUTES = 10
    const appointmentIsNotWithinTenMinutes =
      Math.abs(dayjs().diff(visit?.datetime, 'minutes')) > APPOINTMENT_CUTOFF_MINUTES
    if (appointmentIsNotWithinTenMinutes) {
      if (
        !confirm(
          'Are you sure you want to join this Zoom meeting? This appointment is not starting within 10 minutes.',
        )
      ) {
        return
      }
    }

    analytics.track('Zoom started', {
      zoomUrl,
      patientId,
      visitId: visit?.id,
    })

    window.open(zoomUrl, '_blank')
  }

  const { data: employee } = useEmrQuery(
    'GET /employee/:employeeId',
    { params: { employeeId: visit?.employeeId || '' } },
    { enabled: Boolean(visit?.employeeId && !visit?.calendar) },
  )

  if (!visit) {
    return <PatientVisitNoteLoader />
  }

  const canCompleteWelcomeFlow =
    patientQuery?.data?.statuses?.patient === 'lead' &&
    patientQuery?.data?.workflows?.onboarding?.status !== 'complete' &&
    hasAccess.completeWelcomeFlow

  const canCompleteMedicalIntakeForm =
    patientQuery?.data?.workflows?.medical_intake_form?.status !== 'complete' &&
    isPatientOnboardingOffline(patientQuery?.data)

  const canRemoveNoShow =
    (visit.noShow && (isAdmin(currentUser) || isLeadCoordinator(currentUser))) ||
    (visit.canceled && isEngineer(currentUser))

  const canCancelVisit = !visit.canceled

  const canViewVersionHistory = visit && hasGroupRole(currentUser, 'engineer')

  const canAddAddendum =
    !canEdit &&
    visit &&
    !hasAppointmentType(visit, 'Free Consultation Call', 'Returning welcome call')

  const canCopyPatientZoomLink =
    urlToSendToPatient &&
    hasAccess.canCopyPatientZoomLink &&
    visit &&
    hasAppointmentType(visit, 'Initial Visit', 'Follow-Up Visit')

  const showConfirmationSwitch = isAdmin(currentUser) || isCoordinator(currentUser)

  return (
    <>
      <PatientVisitNoteModals modals={modals} />
      {canCompleteWelcomeFlow && onboardingWorkflowDrawer}
      {canCompleteMedicalIntakeForm && medicalIntakeFormDrawer}
      <Stack>
        <Skeleton visible={appointmentQuery?.isLoading}>
          <Flex gap='md'>
            <Group w='100%' position='apart'>
              <Stack w='100%' spacing='sm'>
                <Group spacing='md' align='baseline' noWrap>
                  <TitleTwo>
                    {capitalize(
                      `${visit.canceled ? 'Canceled ' : ''}${getVisitTypeName(visit.type)} (${
                        visit.duration
                      } min)`,
                    )}
                  </TitleTwo>
                  {showConfirmationSwitch && (
                    <Switch
                      size='sm'
                      label='Confirmed'
                      checked={visit.confirmed}
                      onChange={e =>
                        confirmAppointment.mutate({
                          patientId,
                          appointmentId: visit.id ?? 0,
                          confirmed: e.currentTarget.checked,
                        })
                      }
                    />
                  )}
                </Group>
                <Group noWrap>
                  <Group noWrap spacing='xs'>
                    <UserIcon />
                    <Text>{visit.calendar || employee?.name}</Text>
                  </Group>
                  <Tooltip
                    disabled={!visit.createdBy}
                    label={getScheduledByLabel({ visit, employee })}
                  >
                    <Group noWrap spacing='xs'>
                      <CalendarIcon />
                      <Text>
                        {dayjs(visit.datetime)
                          .tz(visit.timezone)
                          .format('ddd, MM/DD/YYYY | h:mma z')}
                      </Text>
                    </Group>
                  </Tooltip>
                </Group>
              </Stack>
            </Group>
            <Group noWrap spacing='sm'>
              {canCompleteWelcomeFlow && (
                <Tooltip label='Complete welcome flow'>
                  <SecondaryButton onClick={openOnboardingWorkflow} leftIcon={<MapIcon />} />
                </Tooltip>
              )}
              {canCompleteMedicalIntakeForm && (
                <Tooltip label='Complete medical intake form'>
                  <SecondaryButton onClick={openMedicalIntakeForm} leftIcon={<ClipboardIcon />} />
                </Tooltip>
              )}
              <Tooltip label='Schedule'>
                <SecondaryButton
                  onClick={() => {
                    if (carePathway.isEnabled) {
                      setModal({ type: 'care-pathway-modal' })
                    } else {
                      setModal({
                        type: hasGroupRole(currentUser, 'enrollmentCoordinator')
                          ? 'schedule-visit'
                          : 'schedule-visit-calendar',
                      })
                    }
                  }}
                  leftIcon={<CalendarIcon />}
                />
              </Tooltip>
              {canRemoveNoShow && (
                <Tooltip label='Remove no show'>
                  <SecondaryButton
                    onClick={() => modals.restoreVisit.modal.open()}
                    leftIcon={<UserCheckIcon />}
                  />
                </Tooltip>
              )}
              {canCancelVisit && (
                <Tooltip label='Cancel'>
                  <SecondaryButton
                    onClick={() => modals.cancelVisit.modal.open()}
                    leftIcon={<XIcon />}
                  />
                </Tooltip>
              )}
              <Tooltip label={zoomUrl ? 'Open Zoom' : 'No Zoom room'}>
                <SecondaryButton
                  onClick={openZoomRoom}
                  leftIcon={zoomUrl ? <VideoIcon /> : <VideoOffIcon />}
                />
              </Tooltip>
              {canCopyPatientZoomLink &&
                (copied ? (
                  <SecondaryButton leftIcon={<CheckIcon />} />
                ) : (
                  <Tooltip
                    label={
                      isOnboardingOffline
                        ? "Copy patient's Zoom meeting details"
                        : "Copy patient's Zoom link"
                    }
                  >
                    <SecondaryButton leftIcon={<LinkIcon />} onClick={handleCopyZoomLink} />
                  </Tooltip>
                ))}
              {canViewVersionHistory && (
                <Tooltip label='Version history'>
                  <SecondaryButton
                    onClick={() => modals.versionHistory.modal.open()}
                    leftIcon={<ClockIcon />}
                  />
                </Tooltip>
              )}
              {canAddAddendum && (
                <Tooltip label='Add addendum'>
                  <SecondaryButton
                    onClick={() => modals.addendum.modal.open()}
                    leftIcon={<PlusIcon />}
                  />
                </Tooltip>
              )}
            </Group>
          </Flex>
        </Skeleton>
        <Divider />
      </Stack>
    </>
  )
}

type ModalProp = {
  isModalOpen: boolean
  modal: {
    readonly open: () => void
    readonly close: () => void
    readonly toggle: () => void
  }
}

const PatientVisitNoteModals = ({
  modals,
}: {
  modals: {
    addendum: ModalProp
    versionHistory: ModalProp
    cancelVisit: ModalProp
    restoreVisit: ModalProp
  }
}) => {
  const { patientId } = usePatient()

  const { appointmentQuery } = usePatientVisitNote()
  const visit = appointmentQuery?.data

  const { showBanner } = useBanner()

  if (!visit) {
    return null
  }

  return (
    <>
      <CreateAddendumModal
        opened={modals.addendum.isModalOpen}
        onClose={() => modals.addendum.modal.close()}
      />
      <VersionHistoryModal
        opened={modals.versionHistory.isModalOpen}
        onClose={() => modals.versionHistory.modal.close()}
      />
      {/* TODO: update cancel visit modal to accept opened as a prop */}
      {modals.cancelVisit.isModalOpen && (
        <CancelVisitModal
          appointmentTime={visit.datetime}
          setAlert={({ message, type }) =>
            showBanner({ label: message, type: type ?? 'success', dismissable: true })
          }
          patientID={patientId}
          appointmentID={visit.oid}
          closeModal={() => modals.cancelVisit.modal.close()}
        />
      )}
      {/* TODO: update restore visit modal to accept opened as a prop */}
      {modals.restoreVisit.isModalOpen && (
        <RestoreVisitModal
          patientId={patientId}
          appointmentId={visit.oid || ''}
          closeModal={() => modals.restoreVisit.modal.close()}
        />
      )}
    </>
  )
}
