import { useForm } from '@mantine/form'
import { useDidUpdate, useToggle } from '@mantine/hooks'
import {
  Alert,
  DateInput,
  Grid,
  InfoIcon,
  NumberInput,
  Select,
  Stack,
  Textarea,
  TitleThree,
  isAnySelected,
  skipIfEmpty,
  skipIfOtherField,
  validateWith,
} from '@shared/components'
import {
  COCMRegistryItem,
  DischargeReason,
  EMPTY_DISCHARGE_STATE,
  Employee,
  FormattedStripeSubscription,
  LevelOfCareStatus,
  Patient,
  PatientStatus,
  SubscriptionPauseCollectionBehavior,
  dischargeReasonToSubscriptionPauseBehavior,
  dischargeReasons,
  dischargeStatuses,
  getOpheliaHttpError,
  hasRole,
  isAutoPayEnabled,
  isClinician,
  isLeadCareCoordinator,
  levelOfCareStatuses,
  patientStatuses,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { emrApi } from '../../../api'
import { useAuth } from '../../../context/auth'
import {
  getDirtyValue,
  isDate,
  isPositiveNumber,
  isRequired,
  isTodayOrLater,
} from '../../../utils/formValidation'
import { useEmployees, useFlags } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'
import { EditableCol } from './EditableCol'
import { EditableSection } from './EditableSection'

const getBasicData = (patient?: Patient) => {
  return {
    startDate: patient?.personalData?.startDate ?? '',
    patient: (patient?.statuses?.patient ?? '') as PatientStatus,
    levelOfCare: patient?.statuses?.levelOfCare ?? 'n/a',
    primaryClinicianId: patient?.primaryClinician?.id ?? '',
    nurseCareManagerId: patient?.nurseCareManager?.id ?? '',
    // Number input can accept an empty string (to mean empty), but not any other string
    doseSpotId: patient?.doseSpotId ? Number(patient?.doseSpotId) : '',
    dischargeDate: patient?.discharge?.date ?? '',
    dischargeReason: patient?.discharge?.reason ?? '',
    dischargeNote: patient?.discharge?.note ?? '',
    readyForCcmTransition: patient?.ccmTransition?.readyForCcmTransition ?? null,
    reasonForNoCcmTransition: patient?.ccmTransition?.reasonForNoCcmTransition ?? null,
  }
}

const getCocmData = (cocmPatient?: COCMRegistryItem) => {
  return {
    employeeId: cocmPatient?.employeeId ?? '',
  }
}

function dischargeReasonToLabel(reason: DischargeReason): string {
  switch (reason) {
    case 'clinicial': {
      return 'Clinical'
    }
    case 'death': {
      return 'Death'
    }
    case 'payment': {
      return 'Payment'
    }
    case 'non-compliance': {
      return 'Non-compliance'
    }
    case 'non-responsive': {
      return 'Non-responsive'
    }
    case 'ABP non-compliance': {
      return 'ABP non-compliance'
    }
    case 'self-determined end of care': {
      return 'Self-determined end of care'
    }
  }
}

const isEligibleForCcmTransition = (patient: Patient) => {
  const eligibleLevelsOfCare: LevelOfCareStatus[] = ['monthly', 'biweekly']

  const patientLevelOfCare = patient.statuses.levelOfCare

  if (!patientLevelOfCare) {
    return false
  }

  const isEligible = eligibleLevelsOfCare.includes(patientLevelOfCare)

  return isEligible
}

export const PatientProfileClinicalAndCareInformation = () => {
  const { patientQuery, patientId } = usePatient()
  const { currentUser } = useAuth()
  const patient = patientQuery?.data
  const queryClient = useQueryClient()
  const { canUpdateCCM, ccmTransitionSection: ccmTransitionSectionFlag } = useFlags()

  const form = useForm({
    initialValues: getBasicData(patient),
    validate: {
      startDate: validateWith(skipIfEmpty, isDate),
      doseSpotId: validateWith(skipIfEmpty, isPositiveNumber),
      patient: isAnySelected(patientStatuses, 'Required'),
      levelOfCare: isAnySelected(levelOfCareStatuses, 'Required'),
      dischargeDate: validateWith(
        skipIfOtherField('patient', 'not', dischargeStatuses),
        isRequired,
        isDate,
        isTodayOrLater,
      ),
      dischargeReason: validateWith(
        skipIfOtherField('patient', 'not', dischargeStatuses),
        isAnySelected(dischargeReasons, 'Required'),
      ),
      dischargeNote: validateWith(
        skipIfOtherField('patient', 'not', dischargeStatuses),
        isRequired,
      ),
      reasonForNoCcmTransition: validateWith(
        skipIfOtherField('readyForCcmTransition', 'not', 'no'),
        isRequired,
      ),
    },
  })

  const shouldDischarge = dischargeStatuses.includes(form.values.patient)

  const initializeForm = (patient?: Patient) => {
    const values = getBasicData(patient)
    form.setValues(values)
    // Must reset dirty after setting values, otherwise the form will be dirty on initial load
    form.resetDirty(values)
  }

  const employeesQuery = useEmployees({ status: 'currentEmployee' })

  const employees = employeesQuery.data || []

  const clinicians = employees.filter(isClinician)

  let pcOptions = clinicians
    .filter(clinician => clinician.role === 'pc' || clinician.role === 'spc')
    .map(pc => ({ id: pc.oid, name: pc.name }))

  // Add none option to allow for no PC
  pcOptions = [{ id: '', name: 'none' }].concat(pcOptions)

  let ccmOptions = clinicians
    .filter(clinician => hasRole(clinician, 'sncm', 'ncm', 'ncm_tn'))
    .map(ccm => ({ id: ccm.oid, name: ccm.name }))

  // Add none option to allow for no CCM
  ccmOptions = [{ id: '', name: 'none' }].concat(ccmOptions)

  const tnRoles: Employee['role'][] = ['stn', 'tn']
  let tnOptions = clinicians
    .filter(clinician => tnRoles.includes(clinician.role))
    .map(tn => ({ id: tn.oid, name: tn.name }))

  // Add none option to allow for no TN
  tnOptions = [{ id: '', name: 'none' }].concat(tnOptions)

  const [cocmPatientQueryKey, cocmPatientQueryFn] = emrApi.getQuery(
    'GET /cocmRegistry/patient/:patientId',
    { params: { patientId } },
  )

  const cocmPatientQuery = useQuery(cocmPatientQueryKey, cocmPatientQueryFn, {
    enabled: Boolean(patientId),
  })

  const cocmPatient = cocmPatientQuery.data

  const cocmForm = useForm({ initialValues: getCocmData(cocmPatient) })

  const initializeCocmForm = (cocmPatient?: COCMRegistryItem) => {
    const values = getCocmData(cocmPatient)
    cocmForm.setValues(values)
    // Must reset dirty after setting values, otherwise the form will be dirty on initial load
    cocmForm.resetDirty(values)
  }

  const [subscriptionQueryKey, subscriptionQueryFn] = emrApi.getQuery(
    'GET /patient/:patientId/subscription',
    { params: { patientId } },
  )

  const subscriptionQuery = useQuery(subscriptionQueryKey, subscriptionQueryFn, {
    enabled: Boolean(patient) && !patient?.statuses?.insuranceAllowList,
  })

  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'))
  const updateCocmPatient = useMutation(emrApi.getMutation('PUT /cocmRegistry/:patientId'))

  const [isEditing, toggleIsEditing] = useToggle()

  const onSave = async () => {
    if (form.validate().hasErrors) {
      return
    }

    if (form.isDirty()) {
      const primaryClinician = pcOptions.find(({ id }) => id === form.values.primaryClinicianId)
      const nurseCareManager = ccmOptions.find(({ id }) => id === form.values.nurseCareManagerId)

      await updatePatient.mutateAsync({
        params: { patientId },
        data: {
          personalData: {
            startDate: getDirtyValue(form, 'startDate'),
          },
          statuses: {
            patient: getDirtyValue(form, 'patient'),
            levelOfCare: getDirtyValue(form, 'levelOfCare'),
          },
          primaryClinician: {
            id: form.isDirty('primaryClinicianId') ? primaryClinician?.id : undefined,
            name: form.isDirty('primaryClinicianId') ? primaryClinician?.name : undefined,
          },
          nurseCareManager: {
            id: form.isDirty('nurseCareManagerId') ? nurseCareManager?.id : undefined,
            name: form.isDirty('nurseCareManagerId') ? nurseCareManager?.name : undefined,
          },
          doseSpotId:
            // Dosespot id cannot be removed unless set to zero, undefined values are ignored in back-end.
            typeof form.values.doseSpotId === 'number' ? form.values.doseSpotId : undefined,
          discharge:
            // Clear out the data if no longer discharging
            shouldDischarge
              ? {
                  note: form.values.dischargeNote,
                  reason: form.values.dischargeReason,
                  date: form.values.dischargeDate,
                }
              : EMPTY_DISCHARGE_STATE,
          ...(Boolean(form.values.readyForCcmTransition) && {
            ccmTransition: {
              readyForCcmTransition: form.values.readyForCcmTransition,
              reasonForNoCcmTransition:
                form.values.readyForCcmTransition === 'yes'
                  ? null
                  : form.values.reasonForNoCcmTransition,
              updatedAt: dayjs().toISOString(),
            },
          }),
        },
      })
      void patientQuery?.refetch()

      if (form.isDirty('patient') || (form.isDirty('dischargeReason') && subscriptionQuery?.data)) {
        // Optimistically update the subscription query data to reflect the new pauseBehavior. Necessary since the subscription is updated via subscriber.
        queryClient.setQueryData<FormattedStripeSubscription | undefined>(
          subscriptionQueryKey,
          oldData => {
            if (oldData) {
              return {
                ...oldData,
                pauseBehavior: getNewSubscriptonPauseBehavior(
                  form.values.patient,
                  form.values.dischargeReason,
                ),
              }
            }
          },
        )
      }
    }

    if (cocmForm.isDirty()) {
      await updateCocmPatient.mutateAsync({
        params: { patientId },
        data: cocmForm.values,
      })

      void cocmPatientQuery.refetch()
    }

    toggleIsEditing()
  }

  useDidUpdate(() => initializeForm(patient), [patient])
  useDidUpdate(() => initializeCocmForm(cocmPatient), [cocmPatient])

  const getNewSubscriptonPauseBehavior = (
    patientStatus: PatientStatus,
    dischargeReason: DischargeReason | '',
  ): SubscriptionPauseCollectionBehavior | null => {
    if (patientStatus === 'paused') {
      return dischargeReasonToSubscriptionPauseBehavior(dischargeReason)
    }

    if (patient && !isAutoPayEnabled(patient)) {
      return 'keep_as_draft'
    }

    return null
  }

  const getAlertText = () => {
    const newSubscriptionPauseBehavior = getNewSubscriptonPauseBehavior(
      form.values.patient,
      form.values.dischargeReason,
    )
    const isPauseBehaviorChanging =
      newSubscriptionPauseBehavior !== subscriptionQuery?.data?.pauseBehavior

    const isPatientOnCashPay = !patient?.statuses?.insuranceAllowList

    const showPauseSubscriptonAlert =
      isEditing &&
      isPauseBehaviorChanging &&
      isPatientOnCashPay &&
      subscriptionQuery?.data &&
      ((form.values.patient === 'paused' && form.values.dischargeReason) ||
        form.values.patient === 'in treatment')

    if (!showPauseSubscriptonAlert) {
      return null
    }

    const isSubscriptionCurrentlyPaused = Boolean(subscriptionQuery?.data?.pauseBehavior)
    const willSubscriptionBePaused = Boolean(newSubscriptionPauseBehavior)

    // Subscription is currently paused and will remain paused
    if (isSubscriptionCurrentlyPaused && willSubscriptionBePaused) {
      return newSubscriptionPauseBehavior === 'void'
        ? `${patient?.personalData?.firstName}'s subscription will remain paused in Stripe and invoices will be voided`
        : `${patient?.personalData?.firstName}'s subscription will remain paused in Stripe and invoices will continue to be generated in Draft status.`
    }

    // Subscription is currently paused, but will resume
    if (isSubscriptionCurrentlyPaused && !willSubscriptionBePaused) {
      return `${patient?.personalData?.firstName}'s subscription will automatically resume in Stripe.`
    }

    // Subscription is not currently paused, but will be.
    return newSubscriptionPauseBehavior === 'void'
      ? `${patient?.personalData?.firstName}'s subscription will automatically pause in Stripe and invoices will be voided`
      : `${patient?.personalData?.firstName}'s subscription will automatically pause in Stripe and invoices will continue to be generated in Draft status.`

    // This alert is only shown when the pause behavior is changing, so we don't need to handle those cases.
  }

  const alertText = getAlertText()

  const reasonsForNoCcmTransition = [
    'Mental health issues',
    'OUD unstable',
    'Patient refusal',
    'Other',
  ]

  const allowedToUpdateCCM = canUpdateCCM || isLeadCareCoordinator(currentUser)

  return (
    <EditableSection
      isEditing={isEditing}
      onEdit={() => toggleIsEditing()}
      onSave={onSave}
      onCancel={() => {
        toggleIsEditing()
        initializeForm(patient)
        initializeCocmForm(cocmPatient)
      }}
      isLoading={patientQuery?.isLoading || patientQuery?.isError || false}
      isSaving={updatePatient.isLoading || updateCocmPatient.isLoading}
      title={<TitleThree>Clinical & care information</TitleThree>}
      error={getOpheliaHttpError(
        updatePatient.error ?? updateCocmPatient.error,
        `Error updating patient's care information`,
      )}
    >
      <Stack>
        <Grid>
          <EditableCol
            isEditing={isEditing}
            label='Primary clinician'
            text={pcOptions.find(({ id }) => id === form.values.primaryClinicianId)?.name}
          >
            <Select
              placeholder='Primary clinician'
              data={pcOptions.map(pc => ({ label: pc.name, value: pc.id }))}
              {...form.getInputProps('primaryClinicianId')}
            />
          </EditableCol>
          <EditableCol
            isEditing={isEditing}
            label='Clinical care manager'
            text={ccmOptions.find(({ id }) => id === form.values.nurseCareManagerId)?.name}
          >
            {/**
             * Only SPCs, Admins, and ClinOps Associates can update the assigned CCM.
             * Decided to use a LD flag here instead of adding a new role for ClinOps Associates,
             * as they require full CC role permissions, and only need this one Admin permission.
             */}
            <Select
              placeholder='Clinical care manager'
              data={ccmOptions.map(ccm => ({ label: ccm.name, value: ccm.id }))}
              disabled={!allowedToUpdateCCM}
              {...form.getInputProps('nurseCareManagerId')}
            />
          </EditableCol>
          {/* only render this select if the patient is in the COCM registry-- non-editable as we phase this out */}
          {cocmPatient ? (
            <EditableCol
              isEditing={isEditing}
              label='Triage nurse'
              text={tnOptions.find(option => option.id === cocmForm.values.employeeId)?.name}
            >
              <Select
                placeholder='Triange nurse'
                disabled
                data={tnOptions.map(tn => ({ label: tn.name, value: tn.id }))}
                {...cocmForm.getInputProps('employeeId')}
              />
            </EditableCol>
          ) : null}
        </Grid>
        <Grid>
          <EditableCol
            isEditing={isEditing}
            label='Patient status'
            text={capitalize(form.values.patient)}
          >
            <Select
              placeholder='Patient status'
              data={patientStatuses
                .filter(status => status !== 'new')
                .map(status => ({ label: capitalize(status), value: status }))}
              {...form.getInputProps('patient')}
            />
          </EditableCol>
          {shouldDischarge && (
            <>
              <EditableCol
                isEditing={isEditing}
                label='Projected discharge date'
                text={form.values.dischargeDate}
              >
                <DateInput {...form.getInputProps('dischargeDate')} />
              </EditableCol>
              <EditableCol
                isEditing={isEditing}
                label='Discharge reason'
                text={capitalize(form.values.dischargeReason)}
              >
                <Select
                  placeholder='Select one...'
                  data={dischargeReasons.map(reason => ({
                    value: reason,
                    label: dischargeReasonToLabel(reason),
                  }))}
                  {...form.getInputProps('dischargeReason')}
                />
              </EditableCol>
              <EditableCol
                isEditing={isEditing}
                label='Discharge note'
                text={form.values.dischargeNote}
                span={12}
              >
                <Textarea placeholder='Type here...' {...form.getInputProps('dischargeNote')} />
              </EditableCol>
            </>
          )}
          <EditableCol isEditing={isEditing} label='Care level' text={form.values.levelOfCare}>
            <Select
              placeholder='Care level'
              data={levelOfCareStatuses.map(level => ({ label: capitalize(level), value: level }))}
              {...form.getInputProps('levelOfCare')}
            />
          </EditableCol>
          {ccmTransitionSectionFlag && patient && isEligibleForCcmTransition(patient) && (
            <>
              <EditableCol
                isEditing={isEditing}
                label='Ready for CCM transition'
                text={
                  form.values.readyForCcmTransition
                    ? capitalize(form.values.readyForCcmTransition)
                    : undefined
                }
              >
                <Select
                  data={[
                    {
                      label: 'Yes',
                      value: 'yes',
                    },
                    {
                      label: 'No',
                      value: 'no',
                    },
                  ]}
                  {...form.getInputProps('readyForCcmTransition')}
                />
              </EditableCol>
              {form.values.readyForCcmTransition !== 'yes' && (
                <EditableCol
                  isEditing={isEditing}
                  label='Reason'
                  text={form.values.reasonForNoCcmTransition || undefined}
                >
                  <Select
                    label='Reason'
                    data={reasonsForNoCcmTransition.map(reason => ({
                      label: reason,
                      value: reason,
                    }))}
                    {...form.getInputProps('reasonForNoCcmTransition')}
                  />
                </EditableCol>
              )}
            </>
          )}
          <EditableCol isEditing={isEditing} label='Start date' text={form.values.startDate}>
            <DateInput {...form.getInputProps('startDate')} />
          </EditableCol>
          <EditableCol isEditing={isEditing} label='Dose Spot ID' text={form.values.doseSpotId}>
            <NumberInput placeholder='123456' {...form.getInputProps('doseSpotId')} />
          </EditableCol>
        </Grid>
        {alertText && (
          <Alert icon={<InfoIcon />} variant='primary'>
            {alertText}
          </Alert>
        )}
      </Stack>
    </EditableSection>
  )
}
