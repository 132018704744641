import { HDYHAUOptions } from '@shared/types'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../../../../api'
import { useLunaQuery } from '../../../../../utils/hooks'
import { usePatient } from '../../../PPatientContext'
import { WelcomeCallVisitNoteForm } from '../WelcomeCallVisitNote'
import { PatientVisitNoteDefaultText } from '../visitNoteForm/PatientVisitNoteDefaultText'
import { PatientVisitNoteNotDiscussedCheckbox } from '../visitNoteForm/PatientVisitNoteNotDiscussedCheckbox'
import { PatientVisitNoteSection } from '../visitNoteForm/PatientVisitNoteSection'
import { PatientVisitNoteSelect } from '../visitNoteForm/PatientVisitNoteSelect'
import { PatientVisitNoteTextInput } from '../visitNoteForm/PatientVisitNoteTextInput'

export const ReferralSection = ({ form }: { form: WelcomeCallVisitNoteForm }) => {
  const referralPartnersQuery = useQuery(...emrApi.getQuery('GET /referral-partners'))
  const referralPartners = referralPartnersQuery?.data || []

  const isChaseListSelected = form?.values?.referral === 'chase_list'
  const referralPartnersOptions = [
    ...referralPartners
      // If chase_list option is selected, only show referral partners that have chase list
      .filter(referralPartner => !isChaseListSelected || referralPartner?.hasChaseList)
      .map(rp => ({ value: rp.oid, label: rp.name })),
    'N/A',
  ]
  const { patientId } = usePatient()

  const referralQuery = useLunaQuery('GET /workflow-prompt-responses/:patientId/value/:value', {
    params: { patientId, value: 'who_referred_you' },
  })

  const referral = referralQuery?.data?.data?.response

  useEffect(() => {
    /*
     * This checks that the referral partner id is a valid option
     * in case a referral partner is selected and then the user changes the referral type
     */
    if (
      form.values.referralPartnerId &&
      form.values.referralPartnerId !== 'N/A' &&
      !referralPartnersOptions.some(
        option => typeof option !== 'string' && option?.value === form.values.referralPartnerId,
      )
    ) {
      form.setFieldValue('referralPartnerId', '')
    }
  }, [form.values.referralPartnerId, referralPartnersOptions, form])

  return (
    <PatientVisitNoteSection title='Referral'>
      {referral && (
        <PatientVisitNoteDefaultText label='Patient response' value={`Referral: ${referral}`} />
      )}
      <PatientVisitNoteSelect
        label='How did the patient hear about us?'
        placeholder='Select one...'
        data={HDYHAUOptions}
        {...form?.getInputProps('referral')}
      />
      {form.values.referral && ['provider', 'chase_list'].includes(form.values.referral) && (
        <>
          <PatientVisitNoteSelect
            label='Official Ophelia referral partner?'
            placeholder='Select one...'
            data={referralPartnersOptions}
            {...form?.getInputProps('referralPartnerId')}
          />
          <PatientVisitNoteTextInput
            label='Provider name, if not official referral partner (optional)'
            placeholder='Provider name...'
            {...form?.getInputProps('provider')}
          />
          <PatientVisitNoteTextInput
            label='Organization name, if not official referral partner (optional)'
            placeholder='Organization name...'
            {...form?.getInputProps('organization')}
          />
        </>
      )}
      {Boolean(form?.values.referral) && (
        <PatientVisitNoteTextInput
          placeholder='Share any helpful info...'
          {...form?.getInputProps('referral_additional_details')}
        />
      )}
      <PatientVisitNoteNotDiscussedCheckbox field='referral' form={form} />
    </PatientVisitNoteSection>
  )
}
