import { useForm } from '@mantine/form'
import { ChevronRightIcon, PrimaryButton } from '@shared/components'
import { AppointmentTypes, Employee, EmrApi, Patient } from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { appointmentsApi, emrApi } from '../../api'
import { mapIdToName } from '../../utils/utils'
import { CalendarDrawer } from './CalendarDrawer'
import { PickSlotStepProps } from './PickSlotStep'
import { SchedulerContent, SchedulerContentWithTabs } from './SchedulerContent'
import { ClinicianOption, getVisitType } from './SelectClinician'
import { useMultiAvailiabilityQuery } from './use-calendar'

export const IS_STANDBY_VALUE = 'yes'

type SchedulerDrawerProps = PickSlotStepProps & {
  recommendedOptions: ClinicianOption[]
  otherOptions: ClinicianOption[]
  employees: Employee[]
  isInitialVisit: boolean
  patient: Patient
  appointmentTypes?: AppointmentTypes
  setStep?: Dispatch<
    SetStateAction<'schedule type' | 'visit' | 'confirm' | 'schedule' | 'close winback'>
  >
}

export type SchedulingFormData = {
  date: string
  datetime: string
  timezone: string
  employeeId: string
  standbyOptions: string[]
}

export type CalendarDaysDateslot =
  EmrApi['GET /appointments/calendar/:type/days']['res']['dateslots'][number]

export type ScheduleView = 'choose-schedule-type' | 'next-available' | 'choose-clinician'

export const SchedulerDrawer = ({
  closeModal,
  hasInsurance,
  rescheduleAppointment,
  appointmentTypeId,
  patient,
  onBack,
  onSuccess,
  appointmentTypes,
  isInitialVisit,
  recommendedOptions,
  otherOptions,
  employees,
  openWinbacks,
  setStep,
}: SchedulerDrawerProps) => {
  const queryClient = useQueryClient()
  const today = dayjs()
  const maxDaysAhead = 60

  const isUdsOrCheckIn = ['Check-In Call', 'UDS Visit'].includes(
    getVisitType(appointmentTypeId, appointmentTypes),
  )

  const getInitialScheduleView = () => {
    const isClinicianAgnostic = isInitialVisit || isUdsOrCheckIn
    if (isClinicianAgnostic) {
      return 'next-available'
    }

    return 'choose-clinician'
  }

  const [scheduleView, setScheduleView] = useState<ScheduleView>(getInitialScheduleView())

  const changeTab = (value: ScheduleView) => {
    setScheduleView(value)
  }

  const schedulingForm = useForm<SchedulingFormData>({
    initialValues: {
      date: '',
      datetime: '',
      timezone: patient.account?.timezone ?? dayjs.tz.guess(),
      employeeId: '',
      standbyOptions: [] as string[],
    },
    validate: {
      employeeId: value => {
        const allOptions = [...recommendedOptions, ...otherOptions]
        if (
          scheduleView === 'choose-clinician' &&
          !allOptions.find(clinician => clinician.value === value)
        ) {
          return 'Required'
        }
      },
      datetime: value => {
        if (!slots.some(slot => slot.time === value)) {
          return 'Required'
        }
      },
    },
  })

  useEffect(() => {
    if (scheduleView === 'next-available' && !patient?.primaryClinician) {
      return
    }

    const allOptions = [...recommendedOptions, ...otherOptions]
    if (allOptions.length === 1 && !schedulingForm.values.employeeId) {
      schedulingForm.setFieldValue('employeeId', allOptions[0]!.value)
    }
  }, [
    scheduleView,
    patient,
    recommendedOptions,
    otherOptions,
    schedulingForm,
    schedulingForm.setFieldValue,
    schedulingForm.values.employeeId,
  ])

  const employee = employees.find(
    employee => employee.oid === schedulingForm.values.employeeId,
  ) as Employee

  const calendarAvailableSlotsQuery = useQuery(
    ...emrApi.getQuery('GET /appointments/calendar/:type/days', {
      params: { type: appointmentTypeId },
      query: {
        // Only provide calendarId if defined
        calendarId:
          scheduleView === 'choose-clinician' && employee?.calendarId
            ? `${employee.calendarId}`
            : undefined,
        from: today.format('YYYY-MM-DD'),
        to: today.add(maxDaysAhead, 'days').format('YYYY-MM-DD'),
        timezone: 'America/New_York',
        patientID: patient.oid,
        employeeRole: isUdsOrCheckIn ? 'tn' : undefined,
      },
    }),
    {
      onSuccess: ({ dateslots }) => {
        if (dateslots.length > 0) {
          schedulingForm.setFieldValue('date', dateslots[0]!.date)
        }
      },
      cacheTime: toTime('10 sec').ms(),
      enabled: Boolean(employee) || scheduleView === 'next-available',
    },
  )

  const availableCalendarDates = calendarAvailableSlotsQuery.data?.dateslots ?? []

  const maxDate = today.add(maxDaysAhead, 'days')
  const dateRange = availableCalendarDates?.map(slot => slot.date)

  useEffect(() => {
    if (
      calendarAvailableSlotsQuery.data &&
      !calendarAvailableSlotsQuery.data.dateslots.find(slot =>
        dayjs(slot.date).isSame(schedulingForm.values.date, 'day'),
      )
    ) {
      const date =
        calendarAvailableSlotsQuery.data?.dateslots.find(date =>
          dayjs(schedulingForm.values.date).isBefore(date.date),
        ) ?? calendarAvailableSlotsQuery.data.dateslots[0]

      if (date) {
        schedulingForm.setFieldValue('date', date.date)
      }
    }
  }, [
    calendarAvailableSlotsQuery?.data,
    schedulingForm,
    schedulingForm.setFieldValue,
    schedulingForm.values.date,
  ])

  const availableAppointments = useMultiAvailiabilityQuery({
    date: schedulingForm.values.date,
    calendarId: scheduleView === 'choose-clinician' ? employee?.calendarId : undefined,
    appointmentTypeId,
    oneDayOnly: scheduleView === 'next-available',
    dateRange: dateRange ?? [],
    patientID: patient.oid,
    employeeRole: isUdsOrCheckIn ? 'tn' : undefined,
  })

  // Do not show any slots if no clinician is selected on the 'choose-clinician' view
  const slots =
    scheduleView === 'next-available' || employee
      ? availableAppointments.flatMap(date => date.slots)
      : []

  const rescheduleAppointmentMutation = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/appointments/:appointmentId/reschedule'),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('patientsApi.listAppointments')
        void queryClient.invalidateQueries(
          emrApi.getQuery('GET /appointments/calendar/:type/days')[0],
        )
        void queryClient.invalidateQueries(
          emrApi.getQuery('GET /appointments/calendar/:type/slots')[0],
        )
        void queryClient.invalidateQueries(
          emrApi.getQuery('GET /patient/:patientId/appointments', {
            params: { patientId: patient.oid },
          })[0],
        )

        onSuccess?.({
          datetime: dayjs(schedulingForm.values.datetime).tz(schedulingForm.values.timezone),
          appointmentTypeId,
          appointmentTypeName: appointmentTypes
            ? mapIdToName(Number(appointmentTypeId), appointmentTypes)
            : 'Visit',
          employee,
        })

        if (openWinbacks && openWinbacks?.length === 1 && setStep) {
          setStep('close winback')
        } else {
          closeModal()
        }
      },
      onError: () => {
        void queryClient.invalidateQueries(
          emrApi.getQuery('GET /appointments/calendar/:type/days')[0],
        )
      },
    },
  )

  const createAppointmentMutation = useMutation(appointmentsApi.createAppointment, {
    onSuccess: () => {
      void queryClient.invalidateQueries('patientsApi.listAppointments')
      void queryClient.invalidateQueries(
        emrApi.getQuery('GET /appointments/calendar/:type/days')[0],
      )
      void queryClient.invalidateQueries(
        emrApi.getQuery('GET /appointments/calendar/:type/slots')[0],
      )
      void queryClient.invalidateQueries(
        emrApi.getQuery('GET /patient/:patientId/appointments', {
          params: { patientId: patient.oid },
        })[0],
      )

      onSuccess?.({
        datetime: dayjs(schedulingForm.values.datetime).tz(schedulingForm.values.timezone),
        appointmentTypeId,
        appointmentTypeName: appointmentTypes
          ? mapIdToName(Number(appointmentTypeId), appointmentTypes)
          : 'Visit',
        employee,
      })

      if (openWinbacks && openWinbacks?.length === 1 && setStep) {
        setStep('close winback')
      } else {
        closeModal()
      }
    },
    onError: () => {
      void queryClient.invalidateQueries(
        emrApi.getQuery('GET /appointments/calendar/:type/days')[0],
      )
    },
  })

  const submit = () => {
    if (schedulingForm.validate().hasErrors) {
      return
    }

    const selectedTimeslot = slots.find(slot => slot.time === schedulingForm.values.datetime)

    /*
     * There are certain cases where selectedTimeslot.calendarIds is an empty array.
     * In that case, we send an undefined value to acuity. Then, acuity will
     * determine which calendar to use based on the appointment type.
     */

    if (rescheduleAppointment) {
      rescheduleAppointmentMutation.mutate({
        params: {
          patientId: rescheduleAppointment.userId,
          appointmentId: rescheduleAppointment.oid,
        },
        data: {
          calendarId: Number(selectedTimeslot?.calendarIDs[0]),
          datetime: schedulingForm.values.datetime,
          timezone: schedulingForm.values.timezone,
          metadata: {
            isStandby: schedulingForm.values.standbyOptions.includes(IS_STANDBY_VALUE),
            isPrioritySlot: Boolean(selectedTimeslot?.isPriority),
          },
        },
      })
    } else {
      createAppointmentMutation.mutate({
        patientId: patient.oid,
        type: appointmentTypeId,
        calendarId: selectedTimeslot?.calendarIDs[0],
        datetime: schedulingForm.values.datetime,
        metadata: {
          isStandby: schedulingForm.values.standbyOptions.includes(IS_STANDBY_VALUE),
          isPrioritySlot: Boolean(selectedTimeslot?.isPriority),
        },
      })
    }
  }

  return (
    <CalendarDrawer
      appointmentTypes={appointmentTypes}
      appointmentTypeId={appointmentTypeId}
      rescheduleAppointment={rescheduleAppointment}
      onBack={onBack}
      onClose={closeModal}
      button={
        <PrimaryButton
          onClick={submit}
          rightIcon={<ChevronRightIcon />}
          loading={createAppointmentMutation.isLoading}
        >
          {`Confirm & ${rescheduleAppointment ? 're' : ''}schedule visit${
            isInitialVisit && !hasInsurance ? ' as cash pay' : ''
          }`}
        </PrimaryButton>
      }
    >
      {isInitialVisit ? (
        <SchedulerContentWithTabs
          patient={patient}
          schedulingForm={schedulingForm}
          today={today}
          maxDate={maxDate}
          isLoading={calendarAvailableSlotsQuery.isLoading}
          availableAppointments={availableAppointments}
          availableCalendarDates={availableCalendarDates}
          hasInsurance={hasInsurance}
          appointmentTypeId={appointmentTypeId}
          appointmentTypes={appointmentTypes}
          employee={employee}
          recommendedOptions={recommendedOptions}
          otherOptions={otherOptions}
          scheduleView={scheduleView}
          changeTab={changeTab}
        />
      ) : (
        <SchedulerContent
          patient={patient}
          schedulingForm={schedulingForm}
          today={today}
          maxDate={maxDate}
          isLoading={calendarAvailableSlotsQuery.isLoading}
          availableAppointments={availableAppointments}
          availableCalendarDates={availableCalendarDates}
          hasInsurance={hasInsurance}
          scheduleView={scheduleView}
          appointmentTypeId={appointmentTypeId}
          appointmentTypes={appointmentTypes}
          employee={employee}
          recommendedOptions={recommendedOptions}
          otherOptions={otherOptions}
        />
      )}
    </CalendarDrawer>
  )
}
