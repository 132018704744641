import { Accordion, Group, Stack, Text, TitleFour } from '@shared/components'
import { AuthenticationSection } from './AuthenticationSection'
import { CollectionsSection } from './CollectionsSection'
import { OperationsSection } from './OperationsSection'
import { QuickMergeSection } from './QuickMergeSection'
import { SubcollectionsSection } from './SubcollectionsSection'

export const AdminToolsPatientContent = () => {
  return (
    <Stack m='md'>
      <Accordion multiple variant='separated'>
        <Accordion.Item value='quick-merge'>
          <Accordion.Control>
            <TitleFour>Quick merge</TitleFour>
          </Accordion.Control>
          <Accordion.Panel p='sm'>
            <QuickMergeSection />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value='authentication'>
          <Accordion.Control>
            <TitleFour>Authentication</TitleFour>
          </Accordion.Control>
          <Accordion.Panel p='sm'>
            <AuthenticationSection />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='operations'>
          <Accordion.Control>
            <Group>
              <TitleFour>Operations</TitleFour>
              <Text size='xs' color={({ text }) => text[1]}>
                Last 30 days
              </Text>
            </Group>
          </Accordion.Control>
          <Accordion.Panel p='sm'>
            <OperationsSection />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='Collections'>
          <Accordion.Control>
            <TitleFour>Collections</TitleFour>
          </Accordion.Control>
          <Accordion.Panel p='sm'>
            <CollectionsSection />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='subcollections'>
          <Accordion.Control>
            <TitleFour>Subcollections</TitleFour>
          </Accordion.Control>
          <Accordion.Panel p='sm'>
            <SubcollectionsSection />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Stack>
  )
}
