import { Stack, Text, TextInputProps } from '@shared/components'
import { usePatientVisitNote } from '../PatientVisitNoteContext'
import { PatientVisitNoteReadOnlyText } from './PatientVisitNoteReadOnlyText'

// For text that is not input by the user, but we still want to show consistent colors for header and response
export const PatientVisitNoteDefaultText = (props: TextInputProps) => {
  const { canEdit } = usePatientVisitNote()

  if (!canEdit) {
    return <PatientVisitNoteReadOnlyText label={props.label} value={props.value} />
  }

  return (
    <Stack spacing='sm'>
      <Text color={colors => colors.text[0]}>{props.label}</Text>
      <Text color={colors => colors.text[1]}>{props.value}</Text>
    </Stack>
  )
}
