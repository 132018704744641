import {
  AlertIcon,
  CalendarIcon,
  Center,
  ClipboardIcon,
  ClockIcon,
  EM_DASH,
  Group,
  Indicator,
  Pill,
  SecondaryButton,
  Stack,
  Table,
  TertiaryButton,
  Text,
  Th,
  Tooltip,
  useMantineTheme,
} from '@shared/components'
import {
  EMRTaskDecorated,
  EMRTaskStatus,
  EMRTaskType,
  EMRTaskTypeMap,
  getEMRTaskPodShortLabel,
} from '@shared/types'
import { IANAZone, dayjs, name, template } from '@shared/utils'
import range from 'lodash/range'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import LoadingRow from '../../../components/atoms/LoadingRow'
import NoDataRow from '../../../components/atoms/NoDataRow'
import { formatSubCategory } from '../../../utils/emrTasks'
import { useAccess } from '../../../utils/hooks/use-access'
import { useSidePane } from '../../../utils/hooks/use-side-pane'
import { useTasksCache } from '../../../utils/hooks/use-tasks-cache'
import { IssueDrawer } from './IssueDrawer'
import { PriorityIcon } from './PriorityIcon'
import { TaskCountdownSla } from './TaskCountdownSla'
import { ActionMenu } from './actions/ActionMenu'
import { Actions } from './actions/Actions'
import { OrderBy } from './filterFormHelpers'

export type TaskTableProps = {
  tasks: EMRTaskDecorated[]
  isLoading: boolean
  columns: TableColumn[]
  orderBy: OrderBy
  setOrderBy: (orderBy: OrderBy) => void
  status: EMRTaskStatus
  showReferrals?: boolean
}

const TASK_ID_PARAM = 'taskId'

const NUMBER_OF_LOADING_ROWS = 7
const TABLE_COLUMNS = [
  'Pri',
  'Patient name',
  'First opened',
  'Issue type',
  'Sub-category',
  'Scheduled for',
  'Notes',
  'Assignee',
  'Action',
  'Snoozed until',
] as const

type TableColumn = (typeof TABLE_COLUMNS)[number]

const SORT_KEYS: Partial<Record<TableColumn, OrderBy['orderBy']>> = {
  Pri: 'priority',
  'First opened': 'createdAt',
  'Snoozed until': 'remindAt',
  'Scheduled for': 'details.scheduledFor',
}

const FIXED_WIDTH_COLUMNS: TableColumn[] = [
  'Pri',
  'First opened',
  'Scheduled for',
  'Notes',
  'Action',
]

export type TaskOperation =
  | 'notes'
  | 'close'
  | 'reopen'
  | 'edit'
  | 'snooze'
  | 'review_time'
  | 'clinical_contact'
  | 'mbc_form'
  | 'schedule_wellness_visit'

const tasksWithCountdown: EMRTaskType[] = [
  'call_me_now',
  'returning_call_me_now',
  'scheduled',
  'returning_scheduled',
  'winback_call',
  'review_insurance',
]

export const getAssigneeLabel = (task: EMRTaskDecorated) => {
  const pod = getEMRTaskPodShortLabel(task.pod)
  const employeeName = task.employeeId && task.metadata.employeeName

  const label = `${pod || EM_DASH} | ${employeeName || EM_DASH}`

  if (pod && employeeName) {
    return <Text>{label}</Text>
  }
  return (
    <Group spacing='sm'>
      <AlertIcon color={colors => colors.error[0]} />
      <Text color={colors => colors.text[1]}>{label}</Text>
    </Group>
  )
}

const Td = (props: React.ComponentPropsWithoutRef<'td'>) => {
  const styles = useMantineTheme()

  return (
    <td
      className='mantine'
      style={{
        paddingLeft: `calc(${styles.other.sizes.padding.xs} * 3)`,
        paddingRight: `calc(${styles.other.sizes.padding.xs} * 3)`,
      }}
    >
      {props.children}
    </td>
  )
}

export type CurrentTaskOperation = {
  operation: TaskOperation
  task: EMRTaskDecorated
}

const statusText: Record<EMRTaskStatus, { badge: string; text: string }> = {
  open: {
    badge: 'No open issues',
    text: 'Click "+ Issue" to open a new issue',
  },
  snoozed: {
    badge: 'No snoozed issues',
    text: "Snooze an issue when it can't yet be resolved",
  },
  closed: {
    badge: 'No closed issues',
    text: "Close an issue once it's been resolved",
  },
}

const EmptyState = ({ status }: { status: EMRTaskStatus }) => {
  const { badge, text } = statusText[status]

  return (
    <Stack
      align='center'
      justify='center'
      p='md'
      mx='lg'
      sx={({ other: { colors } }) => ({
        backgroundColor: colors.background[1],
        flex: 1,
        alignSelf: 'stretch',
      })}
    >
      <Pill status='none' variant='filled'>
        {badge}
      </Pill>
      <Text>{text}</Text>
    </Stack>
  )
}

export const TaskTable = (props: TaskTableProps) => {
  const tasksCache = useTasksCache()
  const { presentPane, hidePane } = useSidePane()
  const hasAccess = useAccess()
  const [searchParams, setSearchParams] = useSearchParams()
  const [operation, setOperation] = useState<CurrentTaskOperation | undefined>()

  const currentTaskId = searchParams.get(TASK_ID_PARAM)

  const onClose = () => {
    setOperation(undefined)
    tasksCache.invalidate()
  }

  const setAction = ({ operation, task }: CurrentTaskOperation) => {
    setOperation({ operation, task })
  }

  const getAppointmentDatetimeLabel = (appointmentDatetime: string | undefined) => {
    if (dayjs(appointmentDatetime).isToday()) {
      return 'Today'
    } else if (dayjs(appointmentDatetime).isTomorrow()) {
      return 'Tomorrow'
    }
    return dayjs(appointmentDatetime).format('MM/DD/YYYY')
  }

  useEffect(() => {
    if (currentTaskId) {
      presentPane({
        key: `issue-notes-${currentTaskId}`,
        content: (
          <IssueDrawer
            step='notes'
            taskId={currentTaskId}
            onClose={() => {
              hidePane()
              if (location.search) {
                setSearchParams(prev => {
                  prev.delete(TASK_ID_PARAM)
                  return prev
                })
              }
            }}
          />
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTaskId])

  return (
    <>
      <Actions action={operation} onClose={onClose} />
      {!props.isLoading && props.tasks.length === 0 ? (
        <EmptyState status={props.status} />
      ) : (
        <Table
          mx='lg'
          striped
          withBorder
          verticalSpacing='sm'
          sx={({ radius, other: { sizes, colors } }) => ({
            flex: 1,
            tableLayout: 'auto',
            borderWidth: sizes.border.lg,
            borderRadius: radius.sm,
            borderCollapse: 'separate',
            borderStyle: 'solid',
            borderSpacing: '0',
            borderColor: colors.background[2],
          })}
        >
          <thead className='mantine'>
            <tr className='mantine'>
              {props.columns.map(header => {
                const isCurrentColumn = props.orderBy.orderBy === SORT_KEYS[header]
                const isReversed = props.orderBy.orderByDirection === 'asc'
                const toggledOrder = isReversed ? 'desc' : 'asc'
                return (
                  <Th
                    sortable={header in SORT_KEYS}
                    sorted={isCurrentColumn}
                    reversed={isReversed}
                    onSort={() =>
                      props.setOrderBy({
                        orderBy: SORT_KEYS[header],
                        orderByDirection: isCurrentColumn ? toggledOrder : 'desc',
                      })
                    }
                    key={header}
                    style={
                      FIXED_WIDTH_COLUMNS.includes(header)
                        ? { width: `${header.length}ch` }
                        : undefined
                    }
                  >
                    {header}
                  </Th>
                )
              })}
            </tr>
          </thead>
          <tbody className='mantine'>
            {props.isLoading &&
              range(0, NUMBER_OF_LOADING_ROWS + 1).map(i => (
                <LoadingRow key={i} headersLength={props.columns.length} />
              ))}
            {!props.isLoading && props.tasks.length === 0 && (
              <NoDataRow message='No tasks found' headersLength={TABLE_COLUMNS.length} />
            )}
            {!props.isLoading &&
              props.tasks.length > 0 &&
              props.tasks.map(task => {
                return (
                  <tr key={task.oid}>
                    {props.columns.map(col => {
                      switch (col) {
                        case 'Pri':
                          return (
                            <Td key={col}>
                              <PriorityIcon type={task.type} priority={task.priority} />
                            </Td>
                          )
                        case 'Patient name':
                          return (
                            <Td key={col}>
                              {task.metadata.patientLastName === 'n/a' ? (
                                // render empty placeholder for issue types that don't have a patient
                                <div />
                              ) : (
                                <Group spacing='xs' noWrap position='apart'>
                                  <Tooltip
                                    label={template('PC: {pc}{state}', {
                                      pc: task.metadata.primaryClinicianFullName,
                                      state:
                                        task.metadata.patientState === 'n/a'
                                          ? ''
                                          : ` | ${task.metadata.patientState}`,
                                    })}
                                  >
                                    <TertiaryButton
                                      component='a'
                                      target='_blank'
                                      href={`/patients/${task.patientId}`}
                                    >
                                      {name({
                                        first: task.metadata.patientFirstName,
                                        last: task.metadata.patientLastName,
                                      }).lastCommaFirst()}
                                    </TertiaryButton>
                                  </Tooltip>
                                  {props.showReferrals && task.metadata.isReferredPatient && (
                                    <Pill status='success'>Referral</Pill>
                                  )}
                                </Group>
                              )}
                            </Td>
                          )
                        case 'First opened':
                          return (
                            <Td key={col}>
                              <Text>{dayjs(task.createdAt).format('MM/DD/YYYY')}</Text>
                            </Td>
                          )
                        case 'Snoozed until':
                          return (
                            <Td key={col}>
                              <Group spacing='xs' noWrap>
                                <Text style={{ whiteSpace: 'nowrap' }}>
                                  {dayjs(task.remindAt).format('MM/DD/YYYY')}
                                </Text>
                                <Tooltip
                                  label={
                                    <Text bold size='xs' color='white' p='xs'>
                                      {dayjs(task.remindAt).tz(IANAZone.Eastern).format('h:mma z')}
                                    </Text>
                                  }
                                >
                                  <ClockIcon color={colors => colors.actions[0]} />
                                </Tooltip>
                              </Group>
                            </Td>
                          )
                        case 'Issue type':
                          return (
                            <Td key={col}>
                              <Text>
                                {EMRTaskTypeMap.find(type => type.value === task.type)?.label}
                              </Text>
                            </Td>
                          )
                        case 'Scheduled for':
                          return (
                            <Td key={col}>
                              <Group spacing='xs' noWrap>
                                {'details' in task && 'scheduledFor' in task.details && (
                                  <>
                                    <Text style={{ whiteSpace: 'nowrap' }}>
                                      {dayjs(task.details?.scheduledFor)
                                        .tz(IANAZone.Eastern)
                                        .format('h:mma z')}
                                    </Text>
                                    <Tooltip
                                      label={
                                        <Text bold size='xs' color='white' p='xs'>
                                          {getAppointmentDatetimeLabel(task.details?.scheduledFor)}
                                        </Text>
                                      }
                                    >
                                      <CalendarIcon color={colors => colors.actions[0]} />
                                    </Tooltip>
                                  </>
                                )}
                              </Group>
                            </Td>
                          )
                        case 'Sub-category':
                          return (
                            <Td key={col}>
                              <Group spacing='xs' noWrap position='apart'>
                                <Text>
                                  {formatSubCategory(
                                    EMRTaskTypeMap.find(type => type.value === task.type),
                                  )}
                                </Text>
                                {['call_me_now', 'returning_call_me_now'].includes(task.type) && (
                                  <Pill status='success'>CMN</Pill>
                                )}
                                {task.type === 'review_insurance' && (
                                  <Pill status='none'>Care transfer</Pill>
                                )}
                                {/*  @slaCountdownExperiment - 2024-08-09 */}
                                {hasAccess.canSeeSlaCountdown &&
                                  tasksWithCountdown.includes(task.type) &&
                                  props.status !== 'closed' && <TaskCountdownSla task={task} />}
                              </Group>
                            </Td>
                          )
                        case 'Notes':
                          return (
                            <td className='mantine' key={col}>
                              <Indicator
                                // z-index is currently set to 1 to prevent overlapping with the header
                                zIndex={1}
                                label={String(task.metadata.filteredNotesCount)}
                                position='middle-end'
                                inline
                                offset='xs'
                                color={({ actions, error }) =>
                                  task.metadata.filteredNotesCount > 1 ? actions[0] : error[0]
                                }
                              >
                                <Center>
                                  <SecondaryButton
                                    size='xs'
                                    onClick={() => {
                                      setSearchParams({ [TASK_ID_PARAM]: task.oid })
                                    }}
                                    leftIcon={<ClipboardIcon />}
                                  />
                                </Center>
                              </Indicator>
                            </td>
                          )
                        case 'Assignee':
                          return (
                            <Td key={col}>
                              {task.pod || task.employeeId ? (
                                getAssigneeLabel(task)
                              ) : (
                                <TertiaryButton
                                  leftIcon={<AlertIcon color={colors => colors.error[0]} />}
                                  onClick={() => setOperation({ operation: 'edit', task })}
                                >
                                  Assign
                                </TertiaryButton>
                              )}
                            </Td>
                          )
                        case 'Action':
                          return (
                            <td className='mantine' key={col}>
                              <ActionMenu task={task} onClick={setAction} />
                            </td>
                          )
                        default:
                          return <td />
                      }
                    })}
                  </tr>
                )
              })}
          </tbody>
        </Table>
      )}
    </>
  )
}
